<template>
  <div
    :class="[
      'Text-input',
      {
        'Text-input--error': error
      }
    ]"
  >
    <ux-atoms-typo :class="['Text-input-label', { 'accessibility-sr-only': !displayLabel }]" :for="id" as="label" v-if="label">
      {{ labelDisplayed }}
      <span class="accessibility-sr-only" v-if="placeholder || customSrLabel">{{ customSrLabel ?? placeholder }}</span>
    </ux-atoms-typo>
    <div
      :class="[
        'Text-input__container',
        {
          'Text-input__container--icon': accessoryLeft
        }
      ]"
    >
      <ux-atoms-icon :name="accessoryLeft" class="Text-input__icon" v-if="accessoryLeft" />
      <input
        :aria-activedescendant="ariaActivedescendant"
        :aria-autocomplete="ariaAutocomplete"
        :aria-controls="ariaControls"
        :aria-describedby="ariaDescribedBy"
        :aria-expanded="ariaExpanded"
        :aria-invalid="error && !disabled"
        :autocomplete="autocomplete"
        :class="['Text-input__container-input', { 'Text-input__container-input--focus-visible': hasFocusVisible }]"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :role="role"
        :type="type"
        @focusout="removeFocusVisible"
        @keydown.down="focusFirstDropdownEl"
        @keyup="addFocusVisible($event)"
        v-model="value"
      />
    </div>
    <ux-atoms-typo :id="`Text-input-${name}-error-text`" as="p" class="Text-input__error" v-if="error">
      {{ errorText }}
    </ux-atoms-typo>
  </div>
</template>

<script setup lang="ts">
// Taken from wellness_apps/packages/front/src/components/ux/atoms/WlnsForm/WlnsTextInput/WlnsTextInput.vue

import { IconName } from '../../Icon/types';

const { t } = useI18n();

export interface TextInputProps {
  accessoryLeft?: IconName;
  ariaActivedescendant?: string;
  ariaAutocomplete?: string;
  ariaControls?: string;
  ariaExpanded?: boolean | undefined;
  autocomplete?: 'off' | 'on';
  customSrLabel?: string;
  disabled?: boolean;
  displayLabel?: boolean;
  error?: boolean;
  errorText?: string;
  id?: string;
  isOptional?: boolean;
  label?: string;
  modelValue: null | string | undefined;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  role?: string;
  type?: string;
}

const emit = defineEmits(['update:modelValue', 'update:downArrow']);

const props = withDefaults(defineProps<TextInputProps>(), {
  accessoryLeft: undefined,
  ariaActivedescendant: undefined,
  ariaAutocomplete: undefined,
  ariaControls: undefined,
  ariaExpanded: undefined,
  autocomplete: 'on',
  customSrLabel: undefined,
  displayLabel: true,
  error: false,
  errorText: undefined,
  id: undefined,
  label: undefined,
  placeholder: undefined,
  required: false,
  role: undefined,
  type: 'text'
});

const value = useVModel(props, 'modelValue');

const hasFocusVisible = ref<boolean>(false);

const ariaDescribedBy = computed(() => {
  const ariaDescribedBy = [];

  if (props.error) {
    ariaDescribedBy.push(`Text-input-${props.name}-error-text`);
  }

  return ariaDescribedBy.join(' ');
});

const labelDisplayed = computed(() => {
  if (props.label) {
    return `${props.label} ${props.isOptional ? t('ux.atoms.text.optional') : ''}`;
  }
});

const addFocusVisible = (e: Event) => {
  // We ensure the focus was given through the tab focus
  if (e.keyCode === 9) {
    hasFocusVisible.value = true;
  }
};

const removeFocusVisible = () => {
  hasFocusVisible.value = false;
};

const focusFirstDropdownEl = (e) => {
  e.preventDefault();
  emit('update:downArrow');
};

watch(value, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style lang="scss" scoped>
@use 'TextInput.scss';
</style>
