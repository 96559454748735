<template>
  <div :class="['Select', variantClass]">
    <ux-atoms-typo
      :class="['Select__label', { 'accessibility-sr-only': labelIsSr }]"
      :for="`Select__${uniqueId}`"
      as="label"
      color="dark"
      variant="text-medium"
    >
      {{ label }}
      <span class="accessibility-sr-only" v-if="customSrLabel">{{ customSrLabel }}</span>
    </ux-atoms-typo>

    <div class="Select__container">
      <ux-atoms-icon class="Select__chevron" name="chevron-down" size="m" />
      <select
        :class="['Select__select', { 'Select__select--focus-visible': hasFocusVisible }]"
        :id="`Select__${uniqueId}`"
        :max="max"
        :min="min"
        :name="label"
        @focusout="removeFocusVisible"
        @keyup="addFocusVisible($event)"
        v-model="model"
      >
        <option :key="option.number" :value="option.number" v-for="option in options">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface SelectProps {
  customSrLabel?: string;
  label: string;
  labelIsSr?: boolean;
  max?: number;
  min?: number;
  modelValue: number;
  options?: { label: string; number: number }[];
  variant?: 'search';
}

const props = withDefaults(defineProps<SelectProps>(), {
  customSrLabel: undefined,
  label: undefined,
  labelIsSr: false,
  max: undefined,
  min: undefined,
  modelValue: 1,
  options: undefined,
  variant: undefined
});

const uniqueId = useId();

const model = useVModel(props, 'modelValue');

const hasFocusVisible = ref<boolean>(false);

const variantClass = computed(() => {
  return props.variant ? `Select--${props.variant}` : '';
});

const addFocusVisible = (e: Event) => {
  // We ensure the focus was given through the tab focus
  if (e.keyCode === 9) {
    hasFocusVisible.value = true;
  }
};

const removeFocusVisible = () => {
  hasFocusVisible.value = false;
};
</script>
<style lang="scss" scoped>
@use 'Select.scss';
</style>
