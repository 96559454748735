<template>
  <div class="Date-picker">
    <Datepicker
      :action-row="{ showNow: false, showPreview: false, showSelect: false }"
      :aria-labels="ariaLabels"
      :day-names="daysList"
      :enable-minutes="false"
      :enable-time-picker="false"
      :hide-offset-dates="false"
      :locale="locale"
      :max-date="maxDate()"
      :min-date="minDate()"
      :model-value="dateModel"
      :month-change-on-scroll="false"
      :prevent-min-max-navigation="true"
      :start-date="startDate"
      :utc="true"
      :year-range="yearRange"
      @update:model-value="setDate"
      auto-apply
      inline
      month-name-format="long"
      ref="datePicker"
      role="application"
      week-start="0"
    />
  </div>
</template>
<script lang="ts" setup>
import Datepicker from '@vuepic/vue-datepicker';
// eslint-disable-next-line import/no-unassigned-import
import '@vuepic/vue-datepicker/dist/main.css';

import { formatToUTC, newUTCDate, newUTCToday } from '~/helpers/date';
import { daysList } from '~/helpers/daysList';

export interface DatePickerProps {
  modelValue?: string;
  startDate?: string;
}

const props = withDefaults(defineProps<DatePickerProps>(), {
  modelValue: undefined,
  startDate: undefined
});

const emit = defineEmits(['date-picker::close', 'date-picker::updateDate']);

const { locale, t } = useI18n();

const datePicker = ref();

const dateModel = computed(() => {
  return props.modelValue ?? props.startDate;
});

const startDate = computed(() => {
  return props.startDate;
});

const ariaLabels = computed(() => {
  return {
    calendarDays: t('ux.molecules.datePicker.calendarDays'),
    calendarWrap: t('ux.molecules.datePicker.calendarWrap'),
    nextMonth: t('ux.molecules.datePicker.nextMonth'),
    openMonthsOverlay: t('ux.molecules.datePicker.openMonthsOverlay'),
    openYearsOverlay: t('ux.molecules.datePicker.openYearsOverlay'),
    prevMonth: t('ux.molecules.datePicker.prevMonth')
  };
});

const yearRange = computed(() => {
  const today = new Date();
  const year = today.getUTCFullYear();
  const yearPlusOne = today.getUTCFullYear() + 1;
  return [year, yearPlusOne];
});

const minDate = () => newUTCToday();

const maxDate = () => formatToUTC(newUTCToday(), 365);

const setDate = (value: string) => {
  emit('date-picker::updateDate', newUTCDate(value));
};
</script>
<style lang="scss" scoped>
@use 'DatePicker.scss';
</style>
