import { BOOKING_FUNNEL_MAX, BOOKING_FUNNEL_MIN } from '~/helpers/constants';

export const getSelectOptions = () => {
  const optionsArray = [];
  for (let i: number = BOOKING_FUNNEL_MIN; i <= BOOKING_FUNNEL_MAX; i++) {
    optionsArray.push({ label: useNuxtApp().$i18n.t('ux.molecules.bookingFunnel.peopleNth', { n: i }), number: i });
  }

  return optionsArray;
};
