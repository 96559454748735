<template>
  <ul class="Date-selector">
    <li :key="day.value" class="Date-selector__date" v-for="day in daysToDisplay">
      <input
        :checked="isSelectedDay(day.value)"
        :disabled="dayIsDisabled(day.disabled)"
        :id="`date-${day.value}-${uniqueId}`"
        :value="day.value"
        @click="selectDay(day)"
        class="Date-selector__date-input accessibility-sr-only"
        name="date"
        type="radio"
      />
      <label :for="`date-${day.value}-${uniqueId}`" class="Date-selector__date-label">
        <ux-atoms-typo :text="day.name" :variant="'text-small'" as="span" class="Date-selector__date-text text-medium" color="grey-1" />
        <ux-atoms-typo :text="day.date" :variant="'text-small'" as="span" class="Date-selector__date-text" color="grey-1" />
      </label>
    </li>
  </ul>
</template>
<script lang="ts" setup>
import { addDays, formatISO, isAfter, isBefore } from 'date-fns';

import { dateMonthDay, dateName, dateValue } from '~/helpers/date';
import { gtmEvent } from '~/helpers/gtm';

import { bookingObject } from '../BookingFunnel/types';

export interface DateSelectorProps {
  daysWithAvailabilities?: string[];
  isDatePicker?: boolean;
  preSelectedBooking?: bookingObject;
  selectedDate: string;
  startDate?: string;
}

const props = withDefaults(defineProps<DateSelectorProps>(), {
  daysWithAvailabilities: undefined,
  isDatePicker: false,
  preSelectedBooking: undefined,
  selectedDate: undefined,
  startDate: undefined
});

const uniqueId = useId();

const { isDesktop } = useCurrentWindowSize();

const emit = defineEmits(['date::select']);

const anchorDate = ref<string | undefined>(props.startDate);

const firstDayDisplayed = computed(() => {
  return props.selectedDate || anchorDate.value || props.startDate;
});

const initialDate = computed(() => {
  return formatISO(new Date(firstDayDisplayed.value as string), { representation: 'date' });
});

const daysToDisplay = computed(() => {
  const list = [];

  for (let i = 0; i < 6; i++) {
    list.push({
      date: dateMonthDay(i, initialDate.value), // start date + X
      disabled: !props.daysWithAvailabilities?.includes(dateValue(i, initialDate.value)),
      name: dateName(i, initialDate.value), // start date + X name
      value: dateValue(i, initialDate.value) // start  date format iso + X
    });
  }

  return list;
});

const updateAnchorDay = (updatedDate: string) => {
  const daysLimit = isDesktop.value ? 5 : 3;
  // Update anchor date for date selector
  if (isAfter(new Date(updatedDate), addDays(new Date(anchorDate.value as string), daysLimit))) {
    anchorDate.value = dateValue(0, updatedDate);
  }
  if (isBefore(new Date(updatedDate), new Date(anchorDate.value as string))) {
    anchorDate.value = dateValue(0, updatedDate);
  }
};

const isSelectedDay = (dayValue: string) => {
  if (props.isDatePicker) {
    return dayValue === props.selectedDate;
  }
  return props.daysWithAvailabilities?.length ? dayValue === props.selectedDate : false;
};

const dayIsDisabled = (isDisabled: boolean) => {
  return props.isDatePicker ? false : isDisabled;
};

const selectDay = (day: { date: string; disabled: boolean; name: string; value: string }) => {
  gtmEvent('bloc_interact', {
    bloc_interaction: `date: ${day.name} ${day.date}`,
    bloc_name: 'step 1',
    pagename: 'restaurantsandbars::booking::steps'
  });
  emit('date::select', day.value);
};

watch(
  () => props.selectedDate,
  (value) => {
    nextTick(() => {
      updateAnchorDay(value);
    });
  }
);
</script>
<style lang="scss">
@use 'DateSelector.scss';
</style>
