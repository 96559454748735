export const daysList = () => {
  return [
    useNuxtApp().$i18n.t('days.sunday').slice(0, 3),
    useNuxtApp().$i18n.t('days.monday').slice(0, 3),
    useNuxtApp().$i18n.t('days.tuesday').slice(0, 3),
    useNuxtApp().$i18n.t('days.wednesday').slice(0, 3),
    useNuxtApp().$i18n.t('days.thursday').slice(0, 3),
    useNuxtApp().$i18n.t('days.friday').slice(0, 3),
    useNuxtApp().$i18n.t('days.saturday').slice(0, 3)
  ];
};
